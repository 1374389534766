import styled from 'styled-components';

import { Color, colors, fontWeights } from 'theme/theme';

const InitialsContainer = styled.div<{ $round: boolean | undefined }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.selectionGrey};
  font-size: 64px;
  font-weight: ${fontWeights.bold};
  text-align: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: ${(props) => (props.$round ? '100%' : undefined)};
`;

type Props = {
  name: string | undefined;
  size: number;
  round?: boolean;
  backgroundColorName?: Color;
};

export function Initials({ name, size, round, backgroundColorName }: Props) {
  const firstLetter = name ? name[0] : '';

  return (
    <InitialsContainer
      $round={round}
      style={{
        maxWidth: size || '100%',
        maxHeight: size || '100%',
        background: backgroundColorName
          ? colors[backgroundColorName]
          : undefined,
      }}
    >
      {firstLetter}
    </InitialsContainer>
  );
}
