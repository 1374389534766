import { ApiAreaOfFocus } from 'api/types/ApiAreaOfFocus';
import { ApiVolopFunction } from 'api/types/ApiVolopFunction';
import { searchAlgoliaOptionsWithSearchLocation } from 'modules/search/algolia/options/searchAlgoliaOptionsWithSearchLocation';
import { searchAlgoliaConfig } from 'modules/search/algolia/searchAlgoliaConfig';
import { searchAlgoliaVolopsIndex } from 'modules/search/algolia/searchAlgoliaVolopsIndex';
import { SearchAlgoliaRecommendedListing } from 'modules/search/algolia/types/SearchAlgoliaRecommendedListing';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { algoliaSearch } from 'utils/algolia/algoliaSearch';

export async function searchAlgoliaFetchRecommendedVolops(
  searchLocation: Pick<SearchLocation, 'latitude' | 'longitude'>,
  queryOptions?: { distinct: boolean },
  options?: {
    volopFunction?: ApiVolopFunction | null | undefined;
    areaOfFocus?: ApiAreaOfFocus | null | undefined;
    locale?: UserLocale | 'all';
  },
) {
  return algoliaSearch<SearchAlgoliaRecommendedListing>({
    config: searchAlgoliaConfig(),
    query: '',
    options: searchAlgoliaOptionsWithSearchLocation({
      options: {
        index: searchAlgoliaVolopsIndex({ actionDate: null, sort: 'newest' }),
        filters: [
          'actionType:VOLOP',
          'source:IDEALIST',
          options?.volopFunction && `(functions:'${options.volopFunction}')`,
          options?.areaOfFocus && `(areasOfFocus: '${options.areaOfFocus}')`,
          options?.locale &&
            options.locale !== 'all' &&
            `(locale: '${options.locale}')`,
        ]
          .filter(Boolean)
          .join(' AND '),
        hitsPerPage: 20,
        attributesToRetrieve: [
          'objectID',
          'name',
          'city',
          'state',
          'logo',
          'orgName',
          'url',
        ],
        distinct: queryOptions?.distinct,
      },
      searchLocation,
      radius: 'auto',
    }),
  }).then(({ hits }) => hits);
}
