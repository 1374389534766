import styled from 'styled-components';

import { ButtonOrLink } from 'components/ButtonOrLink/ButtonOrLink';
import { colors, cssBreakpoints, shadows, transition } from 'theme/theme';

const smallTileOneWhenHoveredElseZeroVar = '--small-tile-hovered';

export const SmallTileButtonOrLink = styled(ButtonOrLink)`
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: left;
  color: inherit;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: ${shadows.low};
  word-wrap: break-word;
  background: white;
  padding: 20px;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    padding: 30px;
  }

  ${smallTileOneWhenHoveredElseZeroVar}: 0;

  &:hover,
  &:focus {
    color: ${colors.brandBlue};
    ${smallTileOneWhenHoveredElseZeroVar}: 1;
  }
`;

export const SmallTileHoverEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  overflow: hidden;
  pointer-events: none;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background: linear-gradient(
      to left,
      ${colors.lemon},
      ${colors.brandYellow}
    );
    transition: all ${transition};
    transform: translateY(
      calc((var(${smallTileOneWhenHoveredElseZeroVar}) - 1) * 100%)
    );
  }
`;
