import { Box } from 'components/Box/Box';
import { CmsApiRecommendedActionsBlockData } from 'modules/cms/api/types/blocks/CmsApiRecommendedActionsBlock';

import { CmsRecommendedActionsBlockContent } from './CmsRecommendedActionsBlockContent';

type Props = {
  data: CmsApiRecommendedActionsBlockData;
};

export function CmsRecommendedActionsBlock({ data }: Props) {
  const { volopFunction, areaOfFocus } = data;

  return (
    <Box
      data-qa-id="recommended-actions"
      data-qa-volop-function={volopFunction}
      data-qa-area-of-focus={areaOfFocus}
    >
      <CmsRecommendedActionsBlockContent data={data} />
    </Box>
  );
}
