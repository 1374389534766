import { ComponentProps } from 'react';

import { ButtonOrLink } from 'components/ButtonOrLink/ButtonOrLink';

import {
  SmallTileButtonOrLink,
  SmallTileHoverEffect,
} from './SmallTile.styled';

type Props = ComponentProps<typeof ButtonOrLink> & {
  withHoverEffect?: boolean;
};

export function SmallTile({ withHoverEffect, children, ...props }: Props) {
  return (
    <SmallTileButtonOrLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {withHoverEffect && <SmallTileHoverEffect />}
      {children}
    </SmallTileButtonOrLink>
  );
}
