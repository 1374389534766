import { useEffect, useState } from 'react';

import { ApiAreaOfFocus } from 'api/types/ApiAreaOfFocus';
import { ApiVolopFunction } from 'api/types/ApiVolopFunction';
import { searchAlgoliaFetchRecommendedVolops } from 'modules/search/algolia/fetch/searchAlgoliaFetchRecommendedVolops';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { log } from 'utils/logging';

/*
 * Fetch recommended actions
 */
export function useSearchAlgoliaRecommendedVolops(
  searchLocation: Pick<SearchLocation, 'latitude' | 'longitude'>,
  options?: {
    volopFunction?: ApiVolopFunction | null;
    areaOfFocus?: ApiAreaOfFocus | null;
    locale?: UserLocale | 'all';
  },
) {
  // @ts-expect-error TS(2339): Property 'volopFunction' does not exist on type '{ ... Remove this comment to see the full error message
  const { volopFunction, areaOfFocus, locale } = options;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    searchAlgoliaFetchRecommendedVolops(
      searchLocation,
      { distinct: true },
      {
        volopFunction,
        areaOfFocus,
        locale,
      },
    )
      // @ts-expect-error TS(2345): Argument of type 'Hit<TileCarouselResult>[]' is no... Remove this comment to see the full error message
      .then((hits) => setData(hits))
      .catch((e) => {
        setError(e);
        log.error(e);
      })
      .finally(() => setIsLoading(false));
  }, [searchLocation, volopFunction, areaOfFocus, locale]);

  return { data, isLoading, error };
}
